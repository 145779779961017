<template>
    <v-card>
      <v-card-text>
        <v-row class="pa-5 align-center">
          <v-col cols="11">
            <v-row>
              <h2 class="font-weight-bold text-center">Nuevo Ticket</h2>
            </v-row>
          </v-col>
        </v-row>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12">
              <v-text-field max-leng v-model="nombre" :rules="inputRules" label="Asunto" variant="outlined"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea v-model="descripcion" label="Descripcion" variant="outlined"></v-textarea>
            </v-col>
            <v-col cols="6">
                <v-select label="Tipo Producto" :rules="inputRules" persistent-hint return-object variant="outlined"
              v-model="tipoproducto" :items="productoOptions"></v-select>
            </v-col>
            <v-col cols="6">
                <v-text-field type="email" label="Correo de Notificaion" :rules="emailRules" persistent-hint return-object variant="outlined"
              v-model="correo" ></v-text-field>
            </v-col>
            <v-col cols="4">
                <v-file-input ref="archivoFoto1" chips show-size accept="image/*" v-model="foto1" label="FOTO 1"
                variant="outlined"></v-file-input>
            </v-col>
            <v-col cols="4">
                <v-file-input ref="archivoFoto2" chips show-size accept="image/*" v-model="foto2" label="FOTO 2"
                variant="outlined"></v-file-input>
            </v-col>
            <v-col cols="4">
                <v-file-input ref="archivoFoto3" chips show-size accept="image/*" v-model="foto3" label="FOTO 3"
                variant="outlined"></v-file-input>
            </v-col>
          </v-row>
  
          <v-btn class="me-4" color="success" :loading="loading" :disabled="loading" depressed @mousedown="validate"
            @click="guardar">
            Guardar
          </v-btn>
          <v-btn class="me-4 text-white" color="error" to="/mistickets">
            Regresar
          </v-btn>
          <v-snackbar v-model="snackbar" :timeout="3000" :color="snackbarColor">
            {{ snackbarText }}
          </v-snackbar>
  
        </v-form>
      </v-card-text>
    </v-card>
  </template>
  <script>
  
  export default {
    data() {
      return {
        token : null,
        loading: false,
        snackbarText: "",
        snackbar: false,
        snackbarColor: null,
        nombre : null,
        descripcion: null,
        tipoproducto : null,
        correo: null,
        productoOptions : [],
        foto1 : null,
        foto2 : null,
        foto3 : null,
        valid: true,
        maxCaracteres: 11,
        inputRules: [
            (v) => !!v || "Campo requerido"
        ],
        emailRules: [
          (v) => !!v || "Campo requerido",
          (v) => /.+@.+\..+/.test(v) || "El correo electronico debe ser valido"
        ]
        
  
      }
    },
    methods: {
      validate() {
        this.$refs.form.validate();
      },
  
      crearEntidad() {
        var obj = new FormData();
        obj.append("nombre",this.nombre);
        obj.append("descripcion",this.descripcion);
        obj.append("tipoproducto",this.tipoproducto.value);
        obj.append("correo",this.correo);
        const archivo1 = this.$refs.archivoFoto1.files[0];
        const archivo2 = this.$refs.archivoFoto2.files[0];
        const archivo3 = this.$refs.archivoFoto3.files[0];
        obj.append("foto1",archivo1);
        obj.append("foto2",archivo2);
        obj.append("foto3",archivo3);
        return obj;
      },
      getProductos(){
        this.axios({
            headers: {
              Authorization : "Bearer " + this.token
            },
            method : 'GET',
            url : '/productos/listactives',
        }).then(response => {
            response.data.forEach(x => {
                this.loading = false;
                this.productoOptions.push({
                    "title": x.nombre,
                    "value": x.idtipo
                });
            });
        }).catch(e => {
            if (e.hasOwnProperty("response")) {
                this.snackbarText = e.response.data.message;  
            }else{
                this.snackbarText = e.message;
            }
            this.snackbarColor = "error";
            this.snackbar = true;          
            this.loading = false;
        });
      },
      guardar(){
        if (this.valid == false) return;
        this.loading = true;
        this.axios({
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization : "Bearer " + this.token
            },
            method : 'POST',
            url : '/tickets',
            data: this.crearEntidad()
        }).then(response => {
            this.loading = false;            
            this.snackbarText = response.data.message;
            this.snackbarColor = "success";
            this.snackbar = true;     
            setTimeout(() => {
              this.$router.push('/mistickets');
            }, 3000);
        }).catch(e => {
          if (e.hasOwnProperty("response")) {
            this.snackbarText = e.response.data.message;  
          }else{
            this.snackbarText = e.message;
          }
          this.snackbarColor = "error";
          this.snackbar = true;          
          this.loading = false;
        })
      }
    },
    created(){
      this.token = localStorage.getItem("token");
      this.getProductos();
    }
  }
  </script>
  <style lang="">
      
  </style>