import { createRouter, createWebHistory } from 'vue-router'
import UsuarioView from '../views/usuarios/ListaView.vue'
import UsuarioNuevoView from '../views/usuarios/NuevoView.vue'
import EditarNuevoView from '../views/usuarios/EditarView.vue'

import ManualView from '../views/manuales/ListaView.vue'
import ManualNuevoView from '../views/manuales/NuevoView.vue'
import ManualEditarView from '../views/manuales/EditarView.vue'

import ManualDisponibleView from '../views/manuales/ListaDisponiblesView.vue'

import TicketView from '../views/tickets/ListaView.vue'
import TicketNuevoView from '../views/tickets/NuevoView.vue'
import TicketGestionView from '../views/tickets/GestionView.vue'
import VerTicketView from '../views/tickets/VerTicketView.vue'
import DetalleTicketView from '../views/tickets/DetalleTicketView.vue'

import NuevaEmpresaView from '../views/empresa/NuevoView.vue'
import EmpresaView from '../views/empresa/ListaView.vue'
import DetalleEmpresaView from '../views/empresa/DetalleView.vue'

const routes = [
  {
    path: '/',
    name: 'Inicio',
    component: ManualDisponibleView,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: "Login",
    component: () => import('../components/Login.vue')
  },
  {
    path: '/usuarios',
    name: 'Usuarios',
    component: UsuarioView,
    meta: { requiresAuth: true },
  },
  {
    path: '/nuevousuario',
    name: 'Nuevo Usuario',
    component: UsuarioNuevoView,
    meta: { requiresAuth: true },
  },
  {
    path: '/editarusuario/:id',
    name: 'Editar Usuario',
    component: EditarNuevoView,
    meta: { requiresAuth: true },
  },
  {
    path: '/gestionmanuales',
    name: 'Manuales',
    component: ManualView,
    meta: { requiresAuth: true },
  },
  {
    path: '/nuevomanual',
    name: 'Nuevo Manual',
    component: ManualNuevoView,
    meta: { requiresAuth: true },
  },
  {
    path: '/editarmanual/:id',
    name: 'Editar Manual',
    component: ManualEditarView,
    meta: { requiresAuth: true },
  },
  {
    path: '/mistickets',
    name: 'Tickets',
    component: TicketView,
    meta: { requiresAuth: true },
  },
  {
    path: '/nuevoticket',
    name: 'Nuevo Ticket',
    component: TicketNuevoView,
    meta: { requiresAuth: true },
  },
  {
    path: '/gestiontickets',
    name: 'Gestion de Tickets',
    component: TicketGestionView,
    meta: { requiresAuth: true },
  },
  {
    path: '/verticket/:id',
    name: 'Detalle de Ticket',
    component: VerTicketView,
    meta: { requiresAuth: true },
  },
  {
    path: '/detalleticket/:id',
    name: 'Solucion a Ticket',
    component: DetalleTicketView,
    meta: { requiresAuth: true },
  },
  {
    path: '/empresa',
    name: 'Empresa',
    component: EmpresaView,
    meta: { requiresAuth: true },
  },
  {
    path: '/nuevaempresa',
    name: 'Nueva Empresa',
    component: NuevaEmpresaView,
    meta: { requiresAuth: true },
  },
  {
    path: "/detalleempresa/:id",
    name: "Agregar Detalle Empresa",
    component: DetalleEmpresaView,
    meta: {requiresAuth: true}
  }
]

const router = createRouter({
  mode: "history",
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Verificar si el token esta activo
    const token = localStorage.getItem('token');

    if (!token) {
      // Redirigir al inicio de sesion si no hay token
      next({ name: 'Login' })
    } else {
      // Hacer una peticion al servidor para verificar el token
      next();
    }
  } else {
    // Ruta publica, permitir acceso
    next()
  }
});

export default router
