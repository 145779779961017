<template>
    <v-content>
      <v-row class="pa-5 align-center">
        <v-col cols="12">
          <h2 class="font-weight-bold text-center">Empresas</h2>
        </v-col>
      </v-row>
      <v-card>
        <v-progress-linear color="primary" indeterminate :height="9" v-if="loading"></v-progress-linear>
        <v-card-title>
          <v-row>
            <v-col cols="4">
              <v-btn icon="mdi-plus" color="primary" title="Nueva Empresa" to="/nuevaempresa"
                :disabled="cargando"></v-btn>
            </v-col>
            <v-col cols="8">
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar..." single-line
                hide-details></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table dense items-per-page="10" :loading="loading" :search="search" :headers="headers" :items="data"
          item-value="item" items-per-page-text="Total de Registros" class="elevation-1">
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon title="Nuevo Detalle de Alquiler" color="warning" small @click="editItem(item)">
                mdi-plus
            </v-icon>
          </template>
        </v-data-table>
        <v-snackbar v-model="snackbar" :timeout="3000" :color="snackbarColor">
          {{ snackbarText }}
        </v-snackbar>
      </v-card>
    </v-content>
  </template>
  
  <script>
  import { VDataTable } from 'vuetify/labs/VDataTable'
  
  export default {
    name: "Usuarios",
    components: {
      VDataTable
    },
    data() {
      return {
        token : null,
        headers: [
          {
            title: "Codigo",
            align: "start",
            key: "empr_id"
          },
          {
            title: "Codigo Tipo Doc.",
            align: "start",
            key: "empr_tipodoc"
          },
          {
            title: "Tipo Doc.",
            align: "start",
            key: "empr_descripciontipodocumento"
          },
          {
            title: "Numero Doc.",
            align: "start",
            key: "empr_nrodocumento"
          },
          {
            title: "Razon Social",
            align: "start",
            key: "empr_razonsocial"
          },
          {
            title: "Fecha Inicio",
            align: "start",
            key: "empr_fechainicio"
          },
          {
            title: "Opciones",
            align: "start",
            key: "actions",
            sortable: false
          }
        ],
        search: null,
        loading: false,
        data: [],
        snackbarText: "",
        snackbar: false,
        snackbarColor: null
      }
    },
    methods: {
      editItem(item) {
        this.$router.push("/detalleempresa/"+item.empr_id)
      },
      getData(){
        this.loading = true;
        this.axios({
            headers : {
              Authorization : "Bearer " + this.token
            },
            method: "get",
            url : "/empresa"
        }).then(response =>{
            this.data = response.data;
            this.loading = false;
        }).catch(e => {
          if (e.hasOwnProperty("response")) {
            this.snackbarText = e.response.data.message;  
          }else{
            this.snackbarText = e.message;
          }
          this.snackbarColor = "error";
          this.snackbar = true;
          this.loading = false;
        });
      },
    },
    created() {
      this.token = localStorage.getItem("token");
      this.getData();
    }
  
  }
  </script>
  