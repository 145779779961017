<template>
  <v-card>
    <v-layout>
      <v-app-bar app color="primary" v-if="$route.name != 'Login'" dark>
        <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>{{ company }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-app-bar>
      <v-navigation-drawer v-if="$route.name != 'Login'"  v-model="drawer" permanent app>
        <v-list>
          <v-list-item :title="username" :subtitle="rol"
            prepend-avatar="https://redchiroque.com/img/user.png" nav></v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list density="compact" nav>
          <v-list-subheader>OPCIONES</v-list-subheader>
          <div class="list" v-for="(item, i) in items" :key="i" :value="item"  >
            <div v-if="item.subitems.length == 0" >
              <v-list-item :to="item.url" v-if="item.rol.indexOf(idrol) != -1" active-color="primary">
                <template v-slot:prepend>
                  <v-icon :icon="item.icon"></v-icon>
                </template>

                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item>
            </div>
            <div v-else>
              <v-list-group :value="item.title"  v-if="item.rol.indexOf(idrol) != -1">
                <template v-slot:activator="{ props }">
                  <v-list-item
                    v-bind="props"
                    :prepend-icon="item.icon"
                    :title="item.title"
                  ></v-list-item>
                </template>

                <v-list-item
                  v-for="(subitem, j) in item.subitems"
                  :key="j"
                  :value="subitem"
                  :to="subitem.url"
                  active-color="primary"
                >
                  <template v-slot:prepend>
                    <v-icon :icon="subitem.icon"></v-icon>
                  </template>

                  <v-list-item-title v-text="subitem.title"></v-list-item-title>
                </v-list-item>
              </v-list-group>
            </div>
            
          </div>
        </v-list>
        <v-list nav>
          <v-list-item @click="closeSession">
            <template v-slot:prepend>
              <v-icon color="primary">mdi-exit-to-app</v-icon>
            </template>
            <v-list-item-title color="primary">Salir</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-main>
        <router-view />
        <v-footer v-if="$route.name != 'Login'" class="d-flex flex-column">
          <v-col class="text-center">
            <strong>
              Copyright &copy;{{ new Date().getFullYear() }}
              ERP 20+
              Todos los Derechos Reservados.
            </strong>
          </v-col>
        </v-footer>
      </v-main>
    </v-layout>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  name: 'App',
  data: () => ({
    company: null,
    mini: true,
    drawer: true,
    user : null,
    items: []
  }),
  methods: {
    ...mapMutations(['removeUser']),
    closeSession() {
      const token = localStorage.getItem('token');
      if (token) {
        this.axios({
          url: '/logout',
          method: "POST",
          headers : {
            Authorization : "Bearer "+ token
          }
        }).then(response => {
          this.removeUser();
          this.$router.push('/login');
        })
        .catch(error => {
          console.log(error);
          this.removeUser();
          this.$router.push('/login');
        });
      }
    }
  },
  created(){
    this.items = [
      { title: "Inicio", rol: [1,0], icon: "mdi-home-account", url: "/", subitems: [] },
      { title: "Tickets", rol: [0], icon: "mdi-ticket-account", url: "/mistickets", subitems: [] },
      { title: "Tickets", rol: [1,2], icon: "mdi-ticket-account", url: "/gestiontickets", subitems: [] },
      { title: "Mantenimiento", rol:[1], icon: "mdi-book-clock", url: "/mantenimiento", 
                subitems: [
                  {"url":"/usuarios", "icon":"mdi-account-settings","title": "Usuarios"},
                  {"url":"/gestionmanuales", "icon":"mdi-book-cog-outline","title": "Manuales"},
                  {"url":"/empresa", "icon":"mdi-home","title": "Empresa"}
                ]  }    
    ]
    
  },
  computed: {
    company() {      
      return this.$store.getters.user == null ? null : this.$store.getters.user.company;
    },
    username() {      
      return this.$store.getters.user == null ? null : this.$store.getters.user.ruc;
    },
    rol(){      
      return this.$store.getters.user == null ? "" : this.$store.getters.user.ruc == '20601727111' ? "ADMINISTRADOR" : 
              this.$store.getters.user.tipouser == "C" ? "Cliente" : "Trabajador";
    },
    idrol(){
      return this.$store.getters.user == null ? null : this.$store.getters.user.ruc == '20601727111' ? 1 : 
              this.$store.getters.user.tipouser == "C" ? 0 : 2;
    }
  }
}
</script>
