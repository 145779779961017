<template>
    <v-card>
      <v-card-text>
        <v-row class="pa-5 align-center">
          <v-col cols="11">
            <v-row>
              <h2 class="font-weight-bold text-center">Nuevo Manual</h2>
            </v-row>
          </v-col>
        </v-row>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12">
              <v-text-field max-leng v-model="nombre" :rules="inputRules" label="Nombre" variant="outlined"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea v-model="descripcion" label="Descripcion" variant="outlined"></v-textarea>
            </v-col>
            <v-col cols="12">
                <v-file-input ref="archivoInput" chips show-size accept="application/pdf" v-model="pdf" :rules="inputRules" label="PDF"
                variant="outlined"></v-file-input>
            </v-col>
            <v-col cols="12">
                <v-text-field v-model="video" label="Video"
                variant="outlined"></v-text-field>
            </v-col>
          </v-row>
  
          <v-btn class="me-4" color="success" :loading="loading" :disabled="loading" depressed @mousedown="validate"
            @click="guardar">
            Guardar
          </v-btn>
          <v-btn class="me-4 text-white" color="error" to="/gestionmanuales">
            Regresar
          </v-btn>
          <v-snackbar v-model="snackbar" :timeout="3000" :color="snackbarColor">
            {{ snackbarText }}
          </v-snackbar>
  
        </v-form>
      </v-card-text>
    </v-card>
  </template>
  <script>
  
  export default {
    data() {
      return {
        token : null,
        loading: false,
        snackbarText: "",
        snackbar: false,
        snackbarColor: null,
        nombre : null,
        descripcion: null,
        pdf : null,
        video : null,
        valid: true,
        maxCaracteres: 11,
        inputRules: [
            (v) => !!v || "Campo requerido"
        ],
        
  
      }
    },
    methods: {
      validate() {
        this.$refs.form.validate();
      },
  
      crearEntidad() {
        var obj = new FormData();
        obj.append("nombre",this.nombre);
        obj.append("descripcion",this.descripcion);
        const archivo = this.$refs.archivoInput.files[0];
        obj.append("pdf",archivo);
        obj.append("video",this.video);
        return obj;
      },
      guardar(){
        if (this.valid == false) return;
        this.loading = true;
        this.axios({
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization : "Bearer " + this.token
            },
            method : 'POST',
            url : '/manuals',
            data: this.crearEntidad()
        }).then(response => {
            this.loading = false;            
            this.snackbarText = response.data.message;
            this.snackbarColor = "success";
            this.snackbar = true;     
            setTimeout(() => {
              this.$router.push('/gestionmanuales');
            }, 3000);
        }).catch(e => {
          if (e.hasOwnProperty("response")) {
            this.snackbarText = e.response.data.message;  
          }else{
            this.snackbarText = e.message;
          }
          this.snackbarColor = "error";
          this.snackbar = true;          
          this.loading = false;
        })
      }
    },
    created(){
      this.token = localStorage.getItem("token");
    }
  }
  </script>
  <style lang="">
      
  </style>