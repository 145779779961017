<template>
    <v-card>
      <v-card-text>
        <v-row class="pa-5 align-center">
          <v-col cols="11">
            <v-row>
              <h2 class="font-weight-bold text-center">Nuevo Usuario</h2>
            </v-row>
          </v-col>
        </v-row>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="4">
                <v-select label="Tipo Ususario" :rules="inputRules" persistent-hint return-object variant="outlined"
              v-model="tipouser" :items="usersOptions" @update:model-value="cambiarModo"></v-select>
            </v-col>
            <v-col cols="8">
              <v-text-field max-leng v-model="ruc" @keyup="validaRazon" :rules="lenRules" label="RUC" variant="outlined"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="razonsocial" :rules="inputRules" label="Razon Social" :disabled="!activarControl" variant="outlined"></v-text-field>
            </v-col>
            <v-col cols="6">
                <v-text-field v-model="clave" :rules="inputRules" label="Clave" type="password"
              variant="outlined"></v-text-field>
            </v-col>
            <v-col cols="6">
                <v-text-field v-model="repclave" :rules="passConfirm" label="Repetir Clave" type="password"
              variant="outlined"></v-text-field>
            </v-col>
          </v-row>
  
          <v-btn class="me-4" color="success" :loading="loading" :disabled="loading" depressed @mousedown="validate"
            @click="guardar">
            Guardar
          </v-btn>
          <v-btn class="me-4 text-white" color="error" to="/usuarios">
            Regresar
          </v-btn>
          <v-snackbar v-model="snackbar" :timeout="3000" :color="snackbarColor">
            {{ snackbarText }}
          </v-snackbar>
  
        </v-form>
      </v-card-text>
    </v-card>
  </template>
  <script>
  
  export default {
    data() {
      return {
        token : null,
        loading: false,
        snackbarText: "",
        snackbar: false,
        snackbarColor: null,
        ruc : "",
        razonsocial: "",
        clave: "",
        repclave: "",
        tipouser: null,
        usersOptions: [],
        activarControl: false,
        valid: true,
        maxCaracteres: 11,
        minCaracteres: 8,
        inputRules: [
            (v) => !!v || "Campo requerido"
        ],
        lenRules: [
            (v) => !!v || "Campo requerido",
            (v) => /^\d+$/.test(v) || 'Ingresa solo números',
            (v) => (v || '').length <= this.maxCaracteres || `Maximo ${this.maxCaracteres} caracteres`,
            (v) => (v || '').length >= this.minCaracteres || `Minimo ${this.minCaracteres} caracteres`
        ],
        passConfirm: [
            v => !!v || "Confirmar Clave",
            v => v === this.clave || "Claves no coinciden"
        ], 
  
      }
    },
    methods: {
      validate() {
        this.$refs.form.validate();
      },
  
      crearEntidad() {
        return {
          "ruc": this.ruc,
          "tipouser": this.tipouser.value,
          "razonsocial": this.razonsocial,
          "clave": this.clave
        }
      },
      cambiarModo(item){
        this.razonsocial = null;
        this.activarControl = false;
        if(item.value == "U"){
          this.activarControl = true;
        }
      },
      validaRazon(e){
        let valor = ""
        if (this.tipouser != null) {
           valor = this.tipouser.value;
        }
        if(e.which == 13 && valor == 'C'){
            this.axios({
                headers : {
                  Authorization : "Bearer " + this.token
                },
                method : 'GET',
                url : '/users/ruc/'+this.ruc,
            }).then(response => {
                this.razonsocial = response.data.razonsocial;
                this.loading = false;            
            }).catch(e => {
                this.razonsocial = "";
                if (e.hasOwnProperty("response")) {
                  this.snackbarText = e.response.data.message;  
                }else{
                  this.snackbarText = e.message;
                }
                this.snackbarColor = "error";
                this.snackbar = true;          
                this.loading = false;
            });
        }
        
      },
      guardar(){
        if (this.valid == false) return;
        this.loading = true;
        this.axios({
            headers : {
              Authorization : "Bearer " + this.token
            },
            method : 'POST',
            url : '/users',
            data: this.crearEntidad()
        }).then(response => {
            this.loading = false;            
            this.snackbarText = response.data.message;
            this.snackbarColor = "success";
            this.snackbar = true;    
            setTimeout(() => {
              this.$router.push('/usuarios');
            }, 3000); 
        }).catch(e => {
          if (e.hasOwnProperty("response")) {
            this.snackbarText = e.response.data.message;  
          }else{
            this.snackbarText = e.message;
          }
          this.snackbarColor = "error";
          this.snackbar = true;          
          this.loading = false;
        })
      }
    },
    created(){
      this.token = localStorage.getItem("token");
      this.usersOptions = [
        {
          "title" : "CLIENTE",
          "value" : "C"
        },
        {
          "title" : "USUARIO",
          "value": "U"
        }
      ]
    }
  }
  </script>
  <style lang="">
      
  </style>