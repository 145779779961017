import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import VueAxios from 'vue-axios'
import axios from 'axios'

loadFonts()

//axios.defaults.baseURL = 'http://127.0.0.1:8000/api';   //desarrollo
axios.defaults.baseURL = 'https://apigestion.erp20mas.pe/api';   //produccion

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(VueAxios,axios)
  .mount('#app')
