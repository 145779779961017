<template>
    <v-content>
        <v-row class="pa-5 align-center">
            <v-col cols="12">
                <h2 class="font-weight-bold text-center">Gestion de Tickets</h2>
            </v-col>
        </v-row>
        <v-card>
            <v-progress-linear color="primary" indeterminate :height="9" v-if="loading"></v-progress-linear>
            <v-progress-linear color="primary" indeterminate :height="9" v-if="loadingEdit"></v-progress-linear>
            <v-card-title>
                <v-row>
                    <v-col cols="4">
                        &nbsp;
                    </v-col>
                    <v-col cols="8">
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar..." single-line
                            hide-details></v-text-field>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-data-table dense items-per-page="10" :loading="loading" :search="search" :headers="headers" :items="data"
                item-value="item" items-per-page-text="Total de Registros" class="elevation-1">
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon title="Ver Ticket" color="success" small class="mr-2" @click="viewTicket(item)">
                        mdi-eye
                    </v-icon>
                    <v-icon title="Agregar Solucion" color="warning" small class="mr-2" @click="editTicket(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon title="Cerrar Ticket" color="error" small class="mr-2" @click="dialog = true, codigo = item.idticket">
                        mdi-lock-check
                    </v-icon>
                </template>
            </v-data-table>
            <v-dialog v-model="dialog" persistent width="auto">
                <v-card>
                    <v-card-title class="text-h5 text-bold">
                        MENSAJE DEL SISTEMA
                    </v-card-title>
                    <v-card-text>¿DESEA CERRAR TICKET SELECCIONADO?</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red-darken-1" variant="text" @click="dialog = false">
                            NO
                        </v-btn>
                        <v-btn color="green-darken-1" variant="text" @click="closeTicket">
                            SI
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-snackbar v-model="snackbar" :timeout="3000" :color="snackbarColor">
                {{ snackbarText }}
            </v-snackbar>
        </v-card>
    </v-content>
</template>
  
<script>
import { VDataTable } from 'vuetify/labs/VDataTable'

export default {
    name: "Manuales",
    components: {
        VDataTable
    },
    data() {
        return {
            token: null,
            codigo: null,
            headers: [
                {
                    title: "Codigo",
                    align: "start",
                    key: "idticket"
                },
                {
                    title: "Fecha Reg.",
                    align: "start",
                    key: "fechareg"
                },
                {
                    title: "Asunto",
                    align: "start",
                    key: "nombre"
                },
                {
                    title: "Estado",
                    align: "start",
                    key: "estado",
                    value: function (data) {
                        return data.estado == 1 ? 'Abierto' : 'Cerrado';
                    }
                },
                {
                    title: "Opciones",
                    align: "start",
                    key: "actions",
                    sortable: false
                }
            ],
            search: null,
            loading: false,
            loadingEdit: false,
            data: [],
            dialog: false,
            snackbarText: "",
            snackbar: false,
            snackbarColor: null
        }
    },
    methods: {
        viewTicket(item) {
            this.$router.push("/verticket/" + item.idticket);
        },
        editTicket(item) {
            this.loadingEdit = true;
            this.axios({
                headers: {
                    Authorization: "Bearer " + this.token
                },
                method: "GET",
                url: "/historial/revision/" + item.idticket
            }).then(response => {
                this.loadingEdit = false;
                this.$router.push("/detalleticket/" + response.data.idticket);
            }).catch(e => {
                if (e.hasOwnProperty("response")) {
                    this.snackbarText = e.response.data.message;
                } else {
                    this.snackbarText = e.message;
                }

                this.snackbarColor = "error";
                this.snackbar = true;
                this.loadingEdit = false;
            });

        },
        closeTicket() {
            this.loadingEdit = true;
            this.axios({
                headers: {
                    Authorization: "Bearer " + this.token
                },
                method: "DELETE",
                url: "/historial/cerrar/" + this.codigo
            }).then(response => {
                this.dialog = false;
                this.loadingEdit = false;
                this.snackbarText = response.data.message;
                this.snackbarColor = "success";
                this.snackbar = true;     
                setTimeout(() => {
                    this.$router.push('/gestiontickets');
                }, 3000);
            }).catch(e => {
                if (e.hasOwnProperty("response")) {
                    this.snackbarText = e.response.data.message;
                } else {
                    this.snackbarText = e.message;
                }

                this.snackbarColor = "error";
                this.snackbar = true;
                this.loadingEdit = false;
                this.dialog = false;
            });

        },
        getData() {
            this.loading = true;
            this.axios({
                headers: {
                    Authorization: "Bearer " + this.token
                },
                method: "GET",
                url: "/tickets"
            }).then(response => {
                this.data = response.data;
                this.loading = false;
            }).catch(e => {
                if (e.hasOwnProperty("response")) {
                    this.snackbarText = e.response.data.message;
                } else {
                    this.snackbarText = e.message;
                }

                this.snackbarColor = "error";
                this.snackbar = true;
                this.loading = false;
            });
        },
    },
    created() {
        this.token = localStorage.getItem("token");
        this.getData();
    }

}
</script>
  