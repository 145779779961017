<template>
    <v-card>
        <v-card-text>
            <v-row class="pa-5 align-center">
                <v-col cols="11">
                    <v-row>
                        <h2 class="font-weight-bold text-center">Num. Doc: {{ ruc }}</h2>
                    </v-row>
                </v-col>
            </v-row>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                    <v-col cols="12">
                        <v-text-field max-leng v-model="nombre" label="Razon Social" variant="outlined" readonly></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="pa-2 align-center">
                    <v-col cols="12">
                        <h2 class="font-weight-bold text-center">Nuevo Detalle</h2>
                    </v-col>
                </v-row>
                <v-row class="pa-2">
                    <v-col cols="4">
                        <v-text-field v-model="fecha" :rules="inputRules" label="Fecha Inicio" type="date"
                            variant="outlined"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field v-model="fechafin" label="Fecha Fin" type="date"
                            variant="outlined" :rules="inputRules" ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-checkbox
                            v-model="estado"
                            label="Estado"
                        ></v-checkbox>
                    </v-col>
                </v-row>

                <v-btn class="me-6" color="success" :loading="loading" :disabled="loading" depressed @mousedown="validate"
                    @click="guardar">
                    Agregar Detalle
                </v-btn>
                <v-btn class="me-4 text-white" color="error" to="/empresa">
                    Regresar
                </v-btn>
                <v-snackbar v-model="snackbar" :timeout="3000" :color="snackbarColor">
                    {{ snackbarText }}
                </v-snackbar>

            </v-form>
            <v-card-title>
                <v-row class="pa-5 align-center">
                    <v-col cols="12">
                        <h2 class="font-weight-bold text-center">Historial de Detalles</h2>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        &nbsp;
                    </v-col>
                    <v-col cols="8">
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar..." single-line
                            hide-details></v-text-field>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-data-table dense items-per-page="5" :loading="loading" :search="search" :headers="headers" :items="data"
                item-value="item" items-per-page-text="Total de Registros" class="elevation-1">
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon title="Cambiar Estado" color="success" small @click="deleteItem(item)">
                        mdi-upload
                    </v-icon>
                </template>
            </v-data-table>

        </v-card-text>
    </v-card>
            
</template>
<script>
import { VDataTable } from 'vuetify/labs/VDataTable'

export default {
    components: {
        VDataTable
    },
    data() {
        return {
            token: null,
            loading: false,
            headers: [
                {
                    title: "Codigo",
                    align: "start",
                    key: "empd_id"
                },
                {
                    title: "Fecha Inicio",
                    align: "start",
                    key: "empd_fechainicio",
                },
                {
                    title: "Fecha Fin",
                    align: "start",
                    key: "empd_fechafin",
                },
                {
                    title: "Estado",
                    align: "start",
                    key: "empd_estado"
                },
                {
                    title: "Opciones",
                    align: "start",
                    key: "actions",
                    sortable: false
                }
            ],
            search: null,
            data: [],
            snackbarText: "",
            snackbar: false,
            snackbarColor: null,
            codigo: this.$route.params.id,
            ruc: null,
            estado: true,
            nombre: null,
            fecha: null,
            fechafin: null,
            valid: true,
            maxCaracteres: 11,
            inputRules: [
                (v) => !!v || "Campo requerido"
            ],

        }
    },
    methods: {
        validate() {
            this.$refs.form.validate();
        },
        crearEntidad() {
            var obj = {
                "codigo": this.codigo,
                "fechaini" : this.fecha,
                "fechafin": this.fechafin,
                "estado" : this.estado == true ? 1 : 0
            }

            return obj;
        },
        getDetalleEmpresa() {
            this.loading = true;
            this.axios({
                headers: {
                    Authorization: "Bearer " + this.token
                },
                method: 'GET',
                url: '/empresa/' + this.codigo,
            }).then(response => {
                this.nombre = response.data.empresa.empr_razonsocial;
                this.ruc = response.data.empresa.empr_nrodocumento;
                this.data = response.data.detalles;
                this.loading = false;

            }).catch(e => {
                if (e.hasOwnProperty("response")) {
                    this.snackbarText = e.response.data.message;
                } else {
                    this.snackbarText = e.message;
                }
                this.snackbarColor = "error";
                this.snackbar = true;
                this.loading = false;
            });
        },
        deleteItem(item) {
            this.axios({
                headers : {
                Authorization : "Bearer " + this.token
                },
                method : 'DELETE',
                url : '/empresa/'+item.empd_id,
            }).then(response => {
                this.loading = false;            
                this.snackbarText = response.data.message;
                this.snackbarColor = "success";
                this.snackbar = true;  
                setTimeout(() => {
                    this.$router.go(0);
                }, 2000);   
            }).catch(e => {
            if (e.hasOwnProperty("response")) {
                this.snackbarText = e.response.data.message;  
            }else{
                this.snackbarText = e.message;
            }
            this.snackbarColor = "error";
            this.snackbar = true;          
            this.loading = false;
            })
        },
        guardar() {
            if (this.valid == false) return;
            this.loading = true;
            this.axios({
                headers: {
                    Authorization: "Bearer " + this.token
                },
                method: 'POST',
                url: '/detalleempresa',
                data: this.crearEntidad()
            }).then(response => {
                this.loading = false;
                this.snackbarText = response.data.message;
                this.snackbarColor = "success";
                this.snackbar = true;
                setTimeout(() => {
                    this.$router.push('/empresa');
                }, 3000);
            }).catch(e => {
                if (e.hasOwnProperty("response")) {
                    this.snackbarText = e.response.data.message;
                } else {
                    this.snackbarText = e.message;
                }
                this.snackbarColor = "error";
                this.snackbar = true;
                this.loading = false;
            })
        }
    },
    created() {
        this.token = localStorage.getItem("token");
        this.getDetalleEmpresa();
    }
}
</script>
<style lang="">
      
  </style>