<template>
    <v-card>
        <v-card-text>
            <v-row class="pa-5 align-center">
                <v-col cols="11">
                    <v-row>
                        <h2 class="font-weight-bold text-center">Ticket N° {{ codigo }}</h2>
                    </v-row>
                </v-col>
            </v-row>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                    <v-col cols="12">
                        <v-text-field max-leng v-model="nombre" label="Asunto" variant="outlined" readonly></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea v-model="descripcion" label="Descripcion" variant="outlined" readonly></v-textarea>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea v-model="solucion" label="Solucion" :rules="inputRules" variant="outlined"></v-textarea>
                    </v-col>
                    
                    <v-dialog v-model="dialog" fullscreen :scrim="false" transition="dialog-bottom-transition">
                        <v-card>
                            <v-toolbar dark color="primary">
                                <v-btn icon dark @click="dialog = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-toolbar-title>Solucion de ticket Detallada</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                            <v-card-text>
                                <div class="pa-12">{{ detalleSolucion }}</div>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </v-row>

                <v-btn class="me-6" color="success" :loading="loading" :disabled="loading" depressed @mousedown="validate"
                    @click="guardar">
                    Guardar Solucion
                </v-btn>
                <v-btn class="me-4 text-white" color="error" to="/gestiontickets">
                    Regresar
                </v-btn>
                <v-snackbar v-model="snackbar" :timeout="3000" :color="snackbarColor">
                    {{ snackbarText }}
                </v-snackbar>

            </v-form>
            <v-card-title>
                <v-row class="pa-5 align-center">
                    <v-col cols="12">
                        <h2 class="font-weight-bold text-center">Historial de Ticket</h2>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        &nbsp;
                    </v-col>
                    <v-col cols="8">
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar..." single-line
                            hide-details></v-text-field>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-data-table dense items-per-page="5" :loading="loading" :search="search" :headers="headers" :items="data"
                item-value="item" items-per-page-text="Total de Registros" class="elevation-1">
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon v-if="item.detalle != null" title="Ver" color="success" small class="mr-2" @click="openImageModal(item)">
                        mdi-eye
                    </v-icon>
                </template>
            </v-data-table>

        </v-card-text>
    </v-card>
            
</template>
<script>
import { VDataTable } from 'vuetify/labs/VDataTable'

export default {
    components: {
        VDataTable
    },
    data() {
        return {
            token: null,
            loading: false,
            headers: [
                {
                    title: "Codigo",
                    align: "start",
                    key: "idhistorial"
                },
                {
                    title: "Solucion",
                    align: "start",
                    key: "detalle",
                    value: function(item){
                        if(item.detalle == null) return "Ticket Revisado";
                        return String(item.detalle).slice(0, 15) + '...';
                    }
                },
                {
                    title: "Estado",
                    align: "start",
                    key: "estado"
                },
                {
                    title: "Fecha Atencion",
                    align: "start",
                    key: "fechaatencion",
                },
                {
                    title: "Usuario",
                    align: "start",
                    key: "usuario",
                },
                {
                    title: "Opciones",
                    align: "start",
                    key: "actions",
                    sortable: false
                }
            ],
            search: null,
            data: [],
            snackbarText: "",
            snackbar: false,
            snackbarColor: null,
            codigo: this.$route.params.id,
            ruc: null,
            cliente: null,
            nombre: null,
            descripcion: null,
            solucion: null,
            detalleSolucion: null,
            dialog: false,
            valid: true,
            maxCaracteres: 11,
            inputRules: [
                (v) => !!v || "Campo requerido"
            ],

        }
    },
    methods: {
        validate() {
            this.$refs.form.validate();
        },
        openImageModal(item) {
            console.log(item);
            this.detalleSolucion = item.detalle;
            this.dialog = true;
        },
        closeImageModal() {
            this.dialog = false;
        },
        crearEntidad() {
            var obj = {
                "codigo" : this.codigo,
                "solucion": this.solucion
            }

            return obj;
        },
        getDetailTicket() {
            this.loading = true;
            this.axios({
                headers: {
                    Authorization: "Bearer " + this.token
                },
                method: 'GET',
                url: '/tickets/' + this.codigo,
            }).then(response => {
                this.nombre = response.data.historial.asunto;
                this.ruc = response.data.historial.ruc;
                this.cliente = response.data.historial.cliente;
                this.descripcion = response.data.historial.descripcion;
                this.data = response.data.detalles;
                this.loading = false;

            }).catch(e => {
                if (e.hasOwnProperty("response")) {
                    this.snackbarText = e.response.data.message;
                } else {
                    this.snackbarText = e.message;
                }
                this.snackbarColor = "error";
                this.snackbar = true;
                this.loading = false;
            });
        },
        guardar() {
            if (this.valid == false) return;
            this.loading = true;
            this.axios({
                headers: {
                    Authorization: "Bearer " + this.token
                },
                method: 'POST',
                url: '/historial/detalle',
                data: this.crearEntidad()
            }).then(response => {
                this.loading = false;
                this.snackbarText = response.data.message;
                this.snackbarColor = "success";
                this.snackbar = true;
                setTimeout(() => {
                    this.$router.push('/gestiontickets');
                }, 3000);
            }).catch(e => {
                if (e.hasOwnProperty("response")) {
                    this.snackbarText = e.response.data.message;
                } else {
                    this.snackbarText = e.message;
                }
                this.snackbarColor = "error";
                this.snackbar = true;
                this.loading = false;
            })
        }
    },
    created() {
        this.token = localStorage.getItem("token");
        this.getDetailTicket();
    }
}
</script>
<style lang="">
      
  </style>