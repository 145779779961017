<template>
    <v-content>
      <v-row class="pa-5 align-center">
        <v-col cols="12">
          <h2 class="font-weight-bold text-center">Tickets Registrados</h2>
        </v-col>
      </v-row>
      <v-card>
        <v-progress-linear color="primary" indeterminate :height="9" v-if="loading"></v-progress-linear>
        <v-card-title>
          <v-row>
            <v-col cols="4">
                <v-btn icon="mdi-plus" color="primary" title="Nuevo Manual" to="/nuevoticket"
                :disabled="cargando"></v-btn>
            </v-col>
            <v-col cols="8">
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar..." single-line
                hide-details></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table dense items-per-page="10" :loading="loading" :search="search" :headers="headers" :items="data"
          item-value="item" items-per-page-text="Total de Registros" class="elevation-1">
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon title="Ver" color="success" small class="mr-2" @click="viewDetail(item)">
                mdi-eye
            </v-icon>
          </template>
        </v-data-table>
        <v-snackbar v-model="snackbar" :timeout="3000" :color="snackbarColor">
          {{ snackbarText }}
        </v-snackbar>
      </v-card>
    </v-content>
  </template>
  
  <script>
  import { VDataTable } from 'vuetify/labs/VDataTable'
  
  export default {
    name: "Manuales",
    components: {
      VDataTable
    },
    data() {
      return {
        token: null,
        headers: [
          {
            title: "Codigo",
            align: "start",
            key: "idticket"
          },
          {
            title: "Fecha Reg.",
            align: "start",
            key: "fechareg"
          },
          {
            title: "Asunto",
            align: "start",
            key: "nombre"
          },
          {
            title: "Descripcion",
            align: "start",
            key: "descripcion"
          },
          {
            title: "Tipo Producto",
            align: "start",
            key: "tipoproducto"
          },
          {
            title: "Estado",
            align: "start",
            key: "estado",
            value: function(data){
              return data.estado == 1 ? 'Abierto' : 'Cerrado';
            }
          },
          {
            title: "Opciones",
            align: "start",
            key: "actions",
            sortable: false
          }
        ],
        search: null,
        loading: false,
        data: [],
        snackbarText: "",
        snackbar: false,
        snackbarColor: null
      }
    },
    methods: {
        viewDetail(item){
          this.snackbarText = "OPCION AUN NO SE ENCUENTRA HABILITADA!";  
          this.snackbarColor = "error";
          this.snackbar = true;
          this.loading = false;
            /*this.axios({
                headers : {
                  Authorization : "Bearer " + this.token
                },
                method: "get",
                url : "/manuals/view/"+item.pdf,
            }).then(response =>{
                window.open(response.data.path);
            }).catch(e => {
                this.snackbarText = e.message;
                this.snackbarColor = "error";
                this.snackbar = true;
                this.loading = false;
            });*/
        },

      getData(){
        this.loading = true;
        this.axios({
            headers : {
              Authorization : "Bearer " + this.token
            },
            method: "GET",
            url : "/tickets/user"
        }).then(response =>{
            this.data = response.data;
            this.loading = false;
        }).catch(e => {
          if (e.hasOwnProperty("response")) {
            this.snackbarText = e.response.data.message;  
          }else{
            this.snackbarText = e.message;
          }
          
          this.snackbarColor = "error";
          this.snackbar = true;
          this.loading = false;
        });
      },
    },
    created() {
      this.token = localStorage.getItem("token");
      this.getData();
    }
  
  }
  </script>
  