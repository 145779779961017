<template>
    <v-card>
        <v-card-text>
            <v-row class="pa-5 align-center">
                <v-col cols="11">
                    <v-row>
                        <h2 class="font-weight-bold text-center">Ticket N° {{ codigo }}</h2>
                    </v-row>
                </v-col>
            </v-row>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                    <v-col cols="12">
                        <v-text-field max-leng v-model="nombre" label="Asunto" variant="outlined" readonly></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field max-leng v-model="ruc" label="RUC" variant="outlined" readonly></v-text-field>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field max-leng v-model="cliente" label="Cliente" variant="outlined" readonly></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea v-model="descripcion" label="Descripcion" variant="outlined" readonly></v-textarea>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field max-leng v-model="idtipoproducto" label="Cod. Producto" variant="outlined"
                            readonly></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field label="Tipo Producto" persistent-hint return-object variant="outlined"
                            v-model="tipoproducto" readonly></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field type="email" label="Correo de Notificaion" persistent-hint return-object
                            variant="outlined" v-model="correo"></v-text-field>
                    </v-col>
                    <v-col cols="4" v-if="foto1 != null">
                        <v-img class="bg-white" width="300" :aspect-ratio="1" :src="url1" 
                            @click="openImageModal(1)"></v-img>
                    </v-col>
                    <v-col cols="4" v-if="foto2 != null">
                        <v-img class="bg-white" width="300" :aspect-ratio="1" :src="url2" 
                            @click="openImageModal(2)"></v-img>
                    </v-col>
                    <v-col cols="4" v-if="foto3 != null">
                        <v-img class="bg-white" width="300" :aspect-ratio="1" :src="url3" 
                            @click="openImageModal(3)"></v-img>
                    </v-col>
                    <v-dialog v-model="dialog" fullscreen :scrim="false" transition="dialog-bottom-transition">
                        <v-card>
                            <v-toolbar dark color="primary">
                                <v-btn icon dark @click="dialog = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-toolbar-title>Detalles de Imagen</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                            <v-img class="bg-white" width="100%" :src="imageSrc">

                            </v-img>
                        </v-card>
                    </v-dialog>
                </v-row>


                <v-btn class="me-6" color="success" :loading="loading" :disabled="loading" depressed @mousedown="validate"
                    @click="guardar">
                    Marcar Como Revisado
                </v-btn>
                <v-btn class="me-4 text-white" color="error" to="/gestiontickets">
                    Regresar
                </v-btn>
                <v-snackbar v-model="snackbar" :timeout="3000" :color="snackbarColor">
                    {{ snackbarText }}
                </v-snackbar>

            </v-form>
        </v-card-text>
    </v-card>
</template>
<script>

export default {
    data() {
        return {
            token: null,
            loading: false,
            snackbarText: "",
            snackbar: false,
            snackbarColor: null,
            codigo: this.$route.params.id,
            ruc: null,
            cliente: null,
            nombre: null,
            descripcion: null,
            idtipoproducto: null,
            tipoproducto: null,
            correo: null,
            foto1: null,
            foto2: null,
            foto3: null,
            url1: null,
            url2: null,
            url3: null,
            dialog: false,
            valid: true,
            imageSrc: null,
            maxCaracteres: 11,
            inputRules: [
                (v) => !!v || "Campo requerido"
            ],
            emailRules: [
                (v) => !!v || "Campo requerido",
                (v) => /.+@.+\..+/.test(v) || "El correo electronico debe ser valido"
            ]


        }
    },
    methods: {
        validate() {
            this.$refs.form.validate();
        },
        openImageModal(opcion) {
            switch (opcion) {
                case 1:
                    this.imageSrc = this.url1;
                    break;   
                case 2:
                    this.imageSrc = this.url2;
                    break; 
                case 3:
                    this.imageSrc = this.url3;
                    break;          
                
            }
            this.dialog = true;
        },
        closeImageModal() {
            this.dialog = false;
        },
        crearEntidad() {
            var obj = {
                "codigo" : this.codigo
            }

            return obj;
        },
        getDetailTicket() {
            this.loading = true;
            this.axios({
                headers: {
                    Authorization: "Bearer " + this.token
                },
                method: 'GET',
                url: '/tickets/' + this.codigo,
            }).then(response => {
                this.nombre = response.data.historial.asunto;
                this.ruc = response.data.historial.ruc;
                this.cliente = response.data.historial.cliente;
                this.descripcion = response.data.historial.descripcion;
                this.idtipoproducto = response.data.historial.idproducto;
                this.tipoproducto = response.data.historial.producto;
                this.correo = response.data.historial.correo;
                this.foto1 = response.data.historial.foto1;
                this.foto2 = response.data.historial.foto2;
                this.foto3 = response.data.historial.foto3;

                let url = "https://mi.erp20mas.pe/tickets/img/";
                this.url1 = url + this.foto1;
                this.url2 = url + this.foto2;
                this.url3 = url + this.foto3;

                this.loading = false;

            }).catch(e => {
                if (e.hasOwnProperty("response")) {
                    this.snackbarText = e.response.data.message;
                } else {
                    this.snackbarText = e.message;
                }
                this.snackbarColor = "error";
                this.snackbar = true;
                this.loading = false;
            });
        },
        guardar() {
            if (this.valid == false) return;
            this.loading = true;
            this.axios({
                headers: {
                    Authorization: "Bearer " + this.token
                },
                method: 'POST',
                url: '/historial',
                data: this.crearEntidad()
            }).then(response => {
                this.loading = false;
                this.snackbarText = response.data.message;
                this.snackbarColor = "success";
                this.snackbar = true;
                setTimeout(() => {
                    this.$router.push('/gestiontickets');
                }, 3000);
            }).catch(e => {
                if (e.hasOwnProperty("response")) {
                    this.snackbarText = e.response.data.message;
                } else {
                    this.snackbarText = e.message;
                }
                this.snackbarColor = "error";
                this.snackbar = true;
                this.loading = false;
            })
        }
    },
    created() {
        this.token = localStorage.getItem("token");
        this.getDetailTicket();
    }
}
</script>
<style lang="">
      
  </style>