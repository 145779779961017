<template>
    <v-content>
      <v-row class="pa-5 align-center">
        <v-col cols="12">
          <h2 class="font-weight-bold text-center">Manuales Disponibles</h2>
        </v-col>
      </v-row>
      <v-card>
        <v-progress-linear color="primary" indeterminate :height="9" v-if="loading"></v-progress-linear>
        <v-card-title>
          <v-row>
            <v-col cols="4">
              &nbsp;
            </v-col>
            <v-col cols="8">
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar..." single-line
                hide-details></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table dense items-per-page="10" :loading="loading" :search="search" :headers="headers" :items="data"
          item-value="item" items-per-page-text="Total de Registros" class="elevation-1">
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon title="Ver" color="success" small class="mr-2" @click="openPdf(item)">
                mdi-eye
            </v-icon>
            <v-icon title="Descargar" color="warning" small @click="downloadPdf(item)">
              mdi-download
            </v-icon>
            <v-icon title="Video" color="error" small @click="downloadVideo(item)">
              mdi-video
            </v-icon>
          </template>
        </v-data-table>
        <v-snackbar v-model="snackbar" :timeout="3000" :color="snackbarColor">
          {{ snackbarText }}
        </v-snackbar>
      </v-card>
    </v-content>
  </template>
  
  <script>
  import { VDataTable } from 'vuetify/labs/VDataTable'
  
  export default {
    name: "Manuales",
    components: {
      VDataTable
    },
    data() {
      return {
        token: null,
        headers: [
          {
            title: "Codigo",
            align: "start",
            key: "codmanual"
          },
          {
            title: "Nombre",
            align: "start",
            key: "nombre"
          },
          {
            title: "Descripcion",
            align: "start",
            key: "descripcion"
          },
          {
            title: "Estado",
            align: "start",
            key: "estado"
          },
          {
            title: "Opciones",
            align: "start",
            key: "actions",
            sortable: false
          }
        ],
        search: null,
        loading: false,
        data: [],
        snackbarText: "",
        snackbar: false,
        snackbarColor: null
      }
    },
    methods: {
        openPdf(item){
            this.axios({
                headers : {
                  Authorization : "Bearer " + this.token
                },
                method: "get",
                url : "/manuals/view/"+item.pdf,
            }).then(response =>{
                window.open(response.data.path);
            }).catch(e => {
                this.snackbarText = e.message;
                this.snackbarColor = "error";
                this.snackbar = true;
                this.loading = false;
            });
        },
        downloadPdf(item){
            this.axios({
                headers : {
                  Authorization : "Bearer " + this.token
                },
                method: "GET",
                url : "/manuals/pdf/"+item.pdf,
                responseType: 'blob'
            }).then(response =>{
                // Crea una URL de objeto y un enlace para descargar el archivo
                const blob = new Blob([response.data]);
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = item.pdf; // Nombre del archivo descargado
                link.click();

                // Libera la URL del objeto después de descargar
                window.URL.revokeObjectURL(url);
            }).catch(e => {
              if (e.hasOwnProperty("response")) {
                this.snackbarText = "Archivo no existe";  
              }else{
                this.snackbarText = e.message;
              }
              this.snackbarColor = "error";
              this.snackbar = true;
              this.loading = false;
            });
        },
        /*downloadPdf(item){
            window.open(item.pdf);
        },*/
        downloadVideo(item){
          if (item.video != null) {
            window.open(item.video,"_blank");
          }else{
          this.snackbarText = "No existe ruta para video";
          this.snackbarColor = "error";
          this.snackbar = true;
          this.loading = false;
          }
        },

      getData(){
        this.loading = true;
        this.axios({
            headers : {
              Authorization : "Bearer " + this.token
            },
            method: "get",
            url : "/manuals/listactives"
        }).then(response =>{
            this.data = response.data;
            this.loading = false;
        }).catch(e => {
          if (e.hasOwnProperty("response")) {
            this.snackbarText = e.response.data.message;  
          }else{
            this.snackbarText = e.message;
          }
          
          this.snackbarColor = "error";
          this.snackbar = true;
          this.loading = false;
        });
      },
    },
    created() {
      this.token = localStorage.getItem("token");
      this.getData();
    }
  
  }
  </script>
  