import { createStore } from 'vuex'

export default createStore({
  state: {
    user: JSON.parse(localStorage.getItem('user')),
    token: localStorage.getItem('token')
  },
  getters: {
    user: state => {
      return state.user;
    }
  },
  mutations: {
    updateUser(state) {
      state.user = JSON.parse(localStorage.getItem('user'));
      state.token = localStorage.getItem("token");
      
    },   
    removeUser(state){
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      state.user = null;
      state.token = null;   
    }
  },
  actions: {
  },
  modules: {
  }
})